import { useState, useRef } from "react";
import config from "../../../config/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { EditorState } from "draft-js";
import SummernoteLite from "react-summernote-lite";
import 'react-summernote-lite/dist/esm/dist/summernote-lite.min.css';
import draftToHtml from 'draftjs-to-html';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AdminHeader from "../../../element/adminHeader";
import AdminFooter from "../../../element/adminFooter";

export default function EmailTemplateAdd() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");

  const noteRef = useRef();
  const [isClient, setIsClient] = useState(false)



  









  const AddEmail = async (event) => {
    event.preventDefault();

    var options = {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
      },
    };
 
    const textContent = noteRef.current.summernote('code');

    if (!textContent.trim()) {
      alert('Please enter some content in the description field.');

    }else{
      const body = {
        title: title,
        subject: subject,
        description: textContent
  
      };
      const apiUrl = config.API_URL + "email/add";
      await axios
        .post(apiUrl, body, options)
        .then((res) => {
          const msg = res.data.message;
          localStorage.setItem("staticAdded", msg);
          navigate("/admin/emailTemplate", { replace: true });
          console.log("=>>", res);
        })
        .catch((err) => console.log(err));

    }
  
  };

  const onChange = (description) => {
    setDescription({ description });
  };
  return (
    <div>
      <AdminHeader />


      <div className="app-content content">
        <div className="content-wrapper">
          <div className="content-wrapper-before"></div>
          <div className="content-header row"></div>
          <div className="content-body">
            {/* Table head options start  */}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-content collapse show">
                    <div className="card-body">
                    <form

method="post"
onSubmit={AddEmail}
className="form-horizontal needs-validation"
novalidate
>
                        <div className="form-body">
                          <h2 className="form-section">
                            ADD TEMPLATE
                          </h2>

                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-6 col-md-4 col-5">
                                <label htmlFor="title">Title</label>
                                <input
                                  type="text"
                                  id="title"
                                  className="form-control"
                                  required
                                  value={title}
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    setTitle(e.target.value);
                                  }}
                                />
                                <div className="valid-feedback">Valid.</div>
                                <div className="invalid-feedback">
                                  Please fill out this field.
                                </div>
                              </div>

                              <div className="col-sm-6 col-md-4 col-5">
                                <label htmlFor="subject">Subject</label>
                                <input
                                  type="text"
                                  id="subject"
                                  className="form-control"
                                  required
                                  value={subject}
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    setSubject(e.target.value);
                                  }}
                                />
                                <div className="valid-feedback">Valid.</div>
                                <div className="invalid-feedback">
                                  Please fill out this field.
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-sm-12 col-md-12 col-5">
                                <label htmlFor="content">Description/Format</label>
          


                                    <div >
                                      <SummernoteLite

                                        ref={noteRef}
                                        placeholder={"Write something here..."}
                                        tabsize={2}
                                        lang="zh-CN" // only if you want to change the default language
                                        height={350 || "50vh"}
                                        dialogsInBody={true}
                                        blockquoteBreakingLevel={0}
                                        toolbar={[
                                          ['style', ['style']],
                                          ['font', ['bold', 'underline', 'clear', 'strikethrough', 'superscript', 'subscript']],
                                          ['fontsize', ['fontsize']],
                                          ['fontname', ['fontname']],
                                          ['color', ['color']],
                                          ['para', ['ul', 'ol', 'paragraph']],
                                          ['table', ['table']],
                                          ['insert', ['link', 'picture', 'video', 'hr']],
                                          ['view', ['fullscreen', 'codeview', 'help']]
                                        ]}
                                        fontNames={[
                                          "Arial",
                                          "Georgia",
                                          "Verdana",
                                          "e.t.c..."
                                        ]}
                                        onChange={(content) => setDescription(content)}

                                        // callbacks={{
                                        //   onImageUpload: handleImageUpload
                                        // }}
                                      />
                                    </div> 
                              </div>
                            </div>
                          </div>

                          <div className="form-actions">
                            <button
                              type="submit"
                              // onClick={"spinner()"}
                              className="btn btn-primary pull-right d-flex align-items-center sub-btn"
                            >
                              <i className="la la-check-square-o"></i> Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table head options end  */}
          </div>
        </div>
      </div>

      <AdminFooter />
    </div>
  );
}
