import { useEffect, useState } from "react";
import config from "../../../config/config";
import axios from "axios";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import AdminHeader from "../../../element/adminHeader";
import AdminFooter from "../../../element/adminFooter";


export default function PackageAdd() {


  return (
    <div>
      <AdminHeader />


      <AdminFooter />
    </div>
  );
}
