import React, { useEffect, useState, useRef } from "react";
import config from "../../../config/config";
import axios from "axios";
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, ContentState, convertToRaw } from 'draft-js';
import SummernoteLite from "react-summernote-lite";// you need to iport the css style yourself
import 'react-summernote-lite/dist/esm/dist/summernote-lite.min.css'

import draftToHtml from 'draftjs-to-html';
import AdminHeader from "../../../element/adminHeader";
import AdminFooter from "../../../element/adminFooter";

export default function StaticPageEdit() {
  const inputRef = useRef(null);
  const location = useLocation();
  const { lineData } = location.state;
  const { id } = location.state;
  const navigate = useNavigate();
  const [title, setTitle] = useState(lineData.title);
  const [image, setImage] = useState("");
  const [content, setcontent] = useState(lineData.content);


  var options = {
    headers: {
      Authorization: localStorage.getItem("accessToken"),
      'Content-Type': 'multipart/form-data',

    },
  };
  const AddStaticPage = async (event) => {
    event.preventDefault();
    const textContent = inputRef.current.summernote('code');

    if (!textContent.trim()) {
      alert('Please enter some content in the description field.');
     
    } else {
      const body = {
        title: title,
        image: image,
        content: textContent

      }
      const apiUrl = config.API_URL + 'static/' + id;
      await axios.put(apiUrl, body, options)
        .then((res) => {
          const msg = res.data.message;
          localStorage.setItem(
            "staticAdded",
            msg
          );
          navigate('/admin/static', { replace: true });
          console.log("=>>", res);
        }).catch((err) => console.log(err));
    }
  }


  return (
    <div>

      <AdminHeader />

      <div className="app-content content">
        <div className="content-wrapper">
          <div className="content-wrapper-before"></div>
          <div className="content-header row"></div>
          <div className="content-body">
            {/* Table head options start  */}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-content collapse show">
                    <div className="card-body">
                      <form
                        action="/addcompanyuser"
                        method="post"
                        className="form-horizontal needs-validation"
                        novalidate
                      >
                        <div className="form-body">
                          <h2 className="form-section">
                            <i className=""></i> EDIT STATIC PAGE
                          </h2>

                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-6 col-md-4 col-5">
                                <label for="title">Tittle</label>
                                <input
                                  type="text"
                                  id="title"
                                  className="form-control"
                                  name="title"
                                  required
                                  value={title}
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    setTitle(e.target.value);
                                  }}
                                />
                                <div className="valid-feedback">Valid.</div>
                                <div className="invalid-feedback">
                                  Please fill out this field.
                                </div>
                              </div>

                              <div className="col-sm-6 col-md-4 col-5">
                                <label for="image">Upload Image</label>
                                <input
                                  type="file"
                                  id="description"
                                  className="form-control"
                                  name="image"
                                  required
                                  onChange={(e) => {
                                    console.log(e.target.value);
                                    setImage(e.target.files[0])
                                  }}
                                />
                                <div className="valid-feedback">Valid.</div>
                                <div className="invalid-feedback">
                                  Please fill out this field.
                                </div><br />
                                <img alt="Image" src={config.Image_URL + lineData.image} height="100px" width="100px" />

                              </div>
                            </div>

                            <div className="row">

                              <div className="col-sm-12 col-md-12 col-5">
                                <label htmlFor="content">Description/Format</label>
                               
                                  <div>
                                    <SummernoteLite

                                      ref={inputRef}
                                      defaultCodeValue={content}
                                      placeholder={"Write something here..."}
                                      tabsize={2}
                                      lang="zh-CN" // only if you want to change the default language
                                      // height={350 || "50vh"}
                                      dialogsInBody={true}
                                      blockquoteBreakingLevel={0}
                                      toolbar={[
                                        ['style', ['style']],
                                        ['font', ['bold', 'underline', 'clear', 'strikethrough', 'superscript', 'subscript']],
                                        ['fontsize', ['fontsize']],
                                        ['fontname', ['fontname']],
                                        ['color', ['color']],
                                        ['para', ['ul', 'ol', 'paragraph']],
                                        ['table', ['table']],
                                        ['insert', ['link', 'picture', 'video', 'hr']],
                                        ['view', ['fullscreen', 'codeview', 'help']]
                                      ]}
                                      fontNames={[
                                        "Arial",
                                        "Georgia",
                                        "Verdana",
                                        "e.t.c..."
                                      ]}
                                    // callbacks={{
                                    //   onImageUpload: handleImageUpload
                                    // }}
                                    />
                                  </div> 
                              </div>
                            </div>
                          </div>

                          <div className="form-actions">
                            <button
                              type="submit"
                              onClick={AddStaticPage}

                              className="btn btn-primary pull-right"

                            >

                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table head options end  */}
          </div>
        </div>
      </div>

      <AdminFooter />
    </div>
  );
}