import React, { useEffect, useState } from "react";
import AdminHeader from "../../../element/adminHeader";
import AdminFooter from "../../../element/adminFooter";


export default function AllDetails() {

  return (
    <div>
      <AdminHeader />


      <AdminFooter />
    </div>
  );
}
